import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const hubRoutes: Route[] = [
  {
    name: "Include",
    path: `${basePath}/units/:unit_id/hub/include`,
    method: "POST",
    body: [
      {
        name: "dsk",
        label: "DSK",
      },
    ],
  },
  {
    name: "Exclude",
    path: `${basePath}/units/:unit_id/hub/exclude`,
    method: "POST",
  },
  {
    name: "Cancel Discovery",
    path: `${basePath}/units/:unit_id/hub/cancel-discovery`,
    method: "POST",
    body: [
      {
        name: "mode",
        label: "Mode",
        options: [
          { value: "inclusion", label: "Inclusion" },
          { value: "exclusion", label: "Exclusion" },
        ],
      },
    ],
  },
  {
    name: "Provide DSK",
    path: `${basePath}/units/:unit_id/hub/dsk`,
    method: "POST",
    body: [
      {
        name: "dsk",
      },
    ],
  },
  {
    name: "Box Sync",
    path: `${basePath}/units/:unit_id/hub/box-sync`,
    method: "POST",
  },
  {
    name: "Heal Network",
    path: `${basePath}/units/:unit_id/hub/health-network`,
    method: "POST",
  },
  {
    name: "Offline",
    path: `${basePath}/units/:unit_id/hub/offline`,
    method: "POST",
  },
  {
    name: "Zwave Reset",
    path: `${basePath}/units/:unit_id/hub/zwave-reset`,
    method: "POST",
  },
  {
    name: "Reboot",
    path: `${basePath}/units/:unit_id/hub/reboot`,
    method: "POST",
  },
  {
    name: "Hub Two Factor Auth",
    path: `${basePath}/units/:unit_id/hub/:hub_id/token`,
  },
  {
    name: "Get Backup State",
    path: `${basePath}/units/:unit_id/hub/backup`,
    method: "GET",
  },
  {
    name: "Create Backup",
    path: `${basePath}/units/:unit_id/hub/backup`,
    method: "POST",
  },
  {
    name: "Swap",
    path: `${basePath}/units/:unit_id/hub/swap`,
    method: "POST",
  },
  {
    name: "Unlock Primary Lock",
    path: `${basePath}/units/:unit_id/hub/unlock`,
    method: "POST",
  },
];

export default hubRoutes;
